<template>
  <div class="flex flex-col gap-4 py-4 w-full px-3">
    <h1 class="text-xl text-left font-bold border-b">Compensation</h1>
    <ErrorPage v-if="plan === 'basic' || plan === 'standard'" />
    <div v-else>
    <Card>
      <Tabs
        class="mt-2 mx-2"
        :tabs="tabsItems"
        :active-tab="activeTab"
        @currentTab="activeTab = $event"
        :border="true"
      />
    </Card>
    <div>
      <RangeSpread v-if="activeTab === 'Range Spread'" />
      <PayGrade v-if="activeTab === 'Pay Grade'" />
      <Review v-if="activeTab === 'Review'" />
      <Preferences v-if="activeTab === 'Preferences'" />
    </div>
    </div>
  </div>
</template>

<script>
import ErrorPage from "@/modules/Admin/error404";
import Tabs from "@scelloo/cloudenly-ui/src/components/tab"
import Card from "@scelloo/cloudenly-ui/src/components/card"

export default {

  components: {
    Tabs,
    Card,
    ErrorPage,
    PayGrade: () => import('./PayGrade'),
    Review: () => import('./Review'),
    RangeSpread: () => import('./RangeSpread'),
    Preferences: () => import('./Preferences'),
  },
  computed: {
    plan() {
      const result = this.$store.state.subscription ? this.$store.state.subscription.plan : null
      return result
    },
  },

  data() {
    return {
      activeTab: this.$route.query.activeTab || "Range Spread",
      tabsItems: ["Range Spread", "Pay Grade", "Review", "Preferences"]
    }
  }
};
</script>
